.receipt_actions {
    text-align: center;
    margin-bottom: 1rem;
}

.receipt_actions button {
    border-radius: 22px;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0.5rem 0.7rem;
}

.receipt_actions button svg {
    position: relative;
    top: 1px;
}

.receipt_preview{
    background-color: var(--white);
    overflow: auto;
}