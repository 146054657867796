.plan_container {
    border: 1px solid var(--grey);
    padding: 1rem;
}

.plan_perk {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.plan_perk>.check_icon {
    margin-right: 0.5rem;
    position: relative;
    top: 2px;
    color: green;
}

.plan_price {
    text-align: center;
    font-size: 2rem;
    color: var(--header-hover-font-color);
}

.plan_price>.sup {
    font-size: 0.9rem;
    margin-left: 0.3rem;
    font-weight: 500;
    text-transform: uppercase;
}

.plan_container button {
    border: none;
    padding: 0.6rem 0.7rem;
    cursor: pointer;
    white-space: nowrap;
    transition: background 350ms ease-in-out;
    background: var(--primary-color);
    color: var(--white);
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    border-radius: 22px;
}

.plan_container button:active {
    background: var(--darkblack);
}

@media (hover: hover) {
    .plan_container button:hover {
        background: var(--darkblack);
    }
}