.filter_form {
    display: flex;
    align-items: center;
}

.filter_form>div {
    max-width: 250px;
    margin-right: 1rem;
    margin-bottom: 0;
}

.filter_form>button {
    max-width: 250px;
    margin-top: 1.3rem;
}

.report_box {
    border: 2px solid var(--brown);
    width: 250px;
}

.report_box .report_title {
    text-align: center;
    background-color: var(--brown);
    color: var(--white);
    padding: 0.5rem;
}

.report_box .report_contents {
    text-align: center;
    padding: 0.5rem;
}

.report_flex {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.report_flex>.report_box:not(:last-child) {
    margin-right: 1rem;
}

@media(max-width: 767px) {
    .filter_form {
        flex-direction: column;
        justify-content: center;
    }

    .filter_form>div {
        margin-right: 0;
        margin-bottom: 1rem;
        width: 100%;
        max-width: 100%;
    }

    .filter_form>button {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
    }

    .report_flex {
        margin-top: 1rem;
        flex-direction: column;
        justify-content: center;
    }

    .report_flex>.report_box:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}