.main_container {
    width: 80%;
    margin: auto;
}

.left_container h1 {
    font-size: 2.5rem;
}

.left_container p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 1rem auto;
}

.left_container button {
    border-radius: 22px;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
}

.left_container button svg {
    position: relative;
    top: 2px;
    height: 24px;
}

.primary_contents {
    display: flex;
    align-items: center;
}

.left_container {
    width: 40%;
    margin-right: 1rem;
}

.right_container {
    width: 60%;
}

.right_container img {
    width: 100%;
}

.secondary_contents {
    margin-top: 1rem;
}

.secondary_contents h2 {
    font-size: 1.5rem;
}

.secondary_contents p {
    font-size: 1rem;
    margin: 1rem auto;
}

.secondary_contents p.italic {
    font-style: italic;
}

.secondary_contents ul {
    padding-left: 2rem;
}

.secondary_contents ul li {
    line-height: 1.6rem;
}

@media(max-width: 767px) {
    .primary_contents {
        flex-direction: column;
    }

    .main_container {
        width: 95%;
    }

    .left_container {
        margin: auto;
        text-align: center;
    }

    .left_container,
    .right_container {
        width: 100%;
    }

    .left_container button {
        margin-bottom: 1rem;
    }
}