.receipt_container {
    background-color: var(--white);
    color: var(--black);
    margin: 0 auto;
    width: 72mm;
}

.receipt_container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 8pt;
    word-wrap: break-word;
    word-break: break-all;
}

.business_details,
.customer_details {
    margin-bottom: 9pt;
}

.business_name {
    text-align: center;
    font-weight: 600;
}

.business_address,
.business_phone,
.business_gst_no,
.invoice_heading {
    text-align: center;
}

.invoice_heading {
    margin: 9pt auto;
    font-weight: 600;
}

.customer_name,
.customer_phone {
    text-align: right;
}

.bill_details {
    margin-bottom: 3pt;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table td,
.table th {
    text-align: left;
    padding: 3pt;
}

.table td {
    word-wrap: break-word;
}

.table_heading,
.total {
    border-top: 1pt dashed var(--black);
    border-bottom: 1pt dashed var(--black);
}

.qty,
.rate,
.amt,
.subtotal_amt,
.total {
    white-space: nowrap;
}

.rate,
.amt,
.tax_amt,
.subtotal_amt {
    overflow-wrap: anywhere;
}

.sub_total {
    border-top: 1pt dashed var(--black);
}

.taxes * {
    font-size: 7pt;
    font-weight: 500;
}

.taxes>td:first-child {
    text-align: center;
}

.thanks_notes {
    text-align: center;
    margin-top: 3pt;
}

@media print {

    html,
    body {
        height: auto;
    }

    body {
        visibility: hidden;
        background-color: #fff;
    }

    .receipt_container {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
    }
}