.container ul,
.container ol {
    padding-left: 1.5rem;
}

h1 {
    font-size: 1rem;
    font-weight: 500;
}

.container div {
    margin-bottom: 1rem;
    line-height: 1.5rem;
}

.container p{
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
}