.main_footer {
    background-color: var(--footer-bg-color);
    height: 50px;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    overflow: hidden;
}

.main_footer>.separator {
    margin-top: 0;
}

.footer_menu_items {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    list-style: none;
}

.footer_menu_items>li,
.footer_menu_items>li>a {
    height: 100%;
    cursor: pointer;
}

.footer_menu_items>li:not(:last-child) {
    margin-right: 1rem;
}

.footer_menu_items>li>a {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--footer-font-color);
    font-weight: 500;
    transition: all 350ms ease-in-out;
}

.footer_menu_items>li>a:hover,
.footer_menu_items>li>a.active {
    color: var(--footer-hover-font-color);
}

.footer_menu_items>li>a>label {
    font-size: 0.7rem;
    margin-top: 0.1rem;
    white-space: nowrap;
    cursor: pointer;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #212121;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader::after {
    content: '';
    z-index: 3;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3488db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}