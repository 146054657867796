.info_container {
    display: flex;
    align-items: center;
}

.info_container:not(:last-child) {
    margin-bottom: 1rem;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    text-align: right;
}

.info:first-child {
    text-align: left;
}

.label {
    font-weight: 500;
}

.data {
    font-size: 0.8rem;
}