.searchable_container {
    position: relative;
    padding: 0;
}

.searchable_container input {
    padding-right: 1.5rem;
}

.searchable_container>svg {
    position: absolute;
    top: 0.9rem;
    right: 0.3rem;
    background-color: var(--grey);
    color: var(--lightblack);
    border-radius: 50%;
    cursor: pointer;
    pointer-events: none;
}

.dropdown_list {
    width: 100%;
    list-style: none;
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow: auto;
    background-color: var(--bg-color);
    color: var(--font-color);
    border-radius: 7px;
    border: 1px solid var(--grey);
}

.dropdown_list>li {
    display: block;
    width: 100%;
    padding: 0.5rem 0.7rem;
}

.dropdown_list>li:hover,
.dropdown_list>li:active {
    background-color: var(--teal);
    color: var(--white);
}