.settings,
.settings_card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.settings_card {
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0.25rem 1rem 0.25rem;
    background-image: linear-gradient(to bottom, var(--red), var(--black));
    padding: 1rem 1.5rem;
    color: var(--white);
    cursor: pointer;
    transition: all 500ms ease-in-out;
}

.settings_card:hover {
    background-image: linear-gradient(360deg, var(--red), var(--black));
}

.settings_card>svg {
    margin-bottom: 0.5rem;
}

.settings_card>span {
    font-size: 1rem;
}