button {
    border: none;
    padding: 0.6rem 0.7rem;
    cursor: pointer;
    white-space: nowrap;
    transition: background 350ms ease-in-out;
}

.rounded {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 50%;
}

.primary {
    background: var(--primary-color);
    color: var(--white);
}

.success {
    background-color: green;
    color: var(--white);
}

.danger {
    background-color: var(--red);
    color: var(--white);
}

.primary:active {
    background: var(--darkblack);
}

.success:active {
    background: var(--darkblack);
}

.danger:active {
    background: var(--darkblack);
}

@media (hover: hover) {
    button:hover {
        background: var(--darkblack);
    }
}