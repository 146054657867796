.form_group {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
}


.form_group>label {
    font-weight: 500;
}

.form_group>select {
    width: 100%;
    margin-top: 0.3rem;
    padding: 0.5rem 0.7rem;
    outline: none;
    border: 1px solid var(--header-font-color);
    background-color: var(--bg-color);
    color: var(--font-color);
    transition: all 350ms ease-in-out;
}

.error {
    width: 100%;
    background-color: var(--red);
    color: var(--white);
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
}

.form_group>select:focus {
    border-color: var(--input-hover-color);
}