.card {
    max-width: 500px;
    border: 1px solid var(--lightgrey);
    /* box-shadow: 0px 3px 3px var(--lightgrey); */
    padding: 1rem;
}

.centered {
    margin: 0 auto;
}

.full_width {
    max-width: 100%;
}

.card_header {
    color: var(--header-font-color);
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.separator {
    margin-bottom: 1rem;
}