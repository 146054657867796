.red {
    color: var(--red);
    font-weight: 600;
}

.green {
    color: var(--green);
    font-weight: 600;
}

.form_container {
    display: flex;
    flex-wrap: wrap;
}

.form_container > div:not(:last-child) {
    width: 49%;
    min-width: 200px;
    margin-right: 1%;
}

@media screen and (max-width: 768px) {
    .form_container > div:not(:last-child) {
        width: 100%;
        margin-right: 0;
    }
    
}