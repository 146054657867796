.login_btn_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.help_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 0.8rem;
    white-space: nowrap;
}