.items_container {
    overflow: auto;
    max-height: 400px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table td,
.table th {
    border: 1px solid var(--font-color);
    text-align: left;
    padding: 8px;
    font-size: 0.7rem;
}

.actions {
    display: flex;
    align-items: center;
}

.actions>button:first-child {
    margin-right: 0.3rem;
}

.card_title {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    width: 100%;
}

.card_title>svg {
    margin-right: 0.3rem;
}

.load_more {
    text-align: center;
    margin-top: 1rem;
}

.load_more button{
    border-radius: 22px;
}