.details_container {
    display: flex;
    width: 100%;
}

.print_details_container {
    width: 70%;
    margin-right: 1rem;
}

.print_preview_container {
    width: 30%;
}

.card_title {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    width: 100%;
    position: relative;
}

.card_title>svg {
    margin-right: 0.3rem;
}

.card_title>button {
    position: absolute;
    right: 0;
    /* padding: 0.2rem 0.7rem; */
    border-radius: 22px;
}

.card_title>button>svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.card_title>button>span {
    letter-spacing: 2px;
    ;
}

.receipt_actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
}

.receipt_actions>button:not(:last-child) {
    margin-right: 0.5rem;
}

.mb1 {
    margin-bottom: 1rem;
}

.customer_details {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.customer_details>div:first-child {
    margin-right: 1rem;
}

.item_details {
    min-height: 400px;
    overflow: auto;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table td,
.table th {
    border: 1px solid var(--font-color);
    text-align: left;
    padding: 8px;
}

.table td {
    word-wrap: break-word;
}

.table td>div,
.table td>div>div {
    margin-bottom: auto;
}

.table .qty>div input,
.qty {
    width: 150px;
}

.table .item_name>div select {
    min-width: 90px;
}

.table tr:first-child {
    background-color: var(--brown);
    color: var(--white);
}

.action_buttons {
    width: 50px;
}

.action_buttons button {
    margin: auto;
}

.unit {
    width: 90px;
}

.item_name {
    min-width: 150px;
}

.receipt_preview {
    overflow: auto;
    background-color: var(--white);
}

@media(max-width: 767px) {
    .details_container {
        flex-direction: column-reverse;
    }

    .print_details_container,
    .print_preview_container {
        width: 100%;
    }

    .print_details_container {
        margin-right: 0;
    }

    .print_preview_container {
        margin-bottom: 1rem;
    }
}

@media print {
    .item_details {
        height: 0;
    }
}