.mr_05 {
    margin-right: 0.5rem;
}

.add_new_branch_btn {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.add_new_branch_btn>svg {
    margin-right: 0.3rem;
}

.items_container {
    overflow: auto;
    max-height: 400px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table td,
.table th {
    border: 1px solid var(--font-color);
    text-align: left;
    padding: 8px;
    font-size: 0.7rem;
}

.actions {
    display: flex;
    align-items: center;
}

.actions>button:first-child {
    margin-right: 0.3rem;
}

.header_with_back_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.header_with_back_btn>span:last-child {
    color: var(--footer-hover-font-color);
    margin-right: 0.5rem;
    border: 1px solid var(--footer-hover-font-color);
    font-size: 0.7rem;
    padding: 0.3rem;
    transition: all 350ms ease-in-out;
    cursor: pointer;
}

.header_with_back_btn>span:last-child:hover,
.header_with_back_btn>span:last-child:active {
    border-color: var(--teal);
    color: var(--teal);
}

.header_with_back_btn>span>svg,
.header_with_back_btn>span>span {
    vertical-align: middle;
}

.header_with_back_btn>span>span {
    margin-left: 0.3rem;
}