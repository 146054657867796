.main_header {
    background-color: var(--header-bg-color);
    color: var(--header-font-color);
    padding: 0.7rem;
    min-width: 320px;
}

.main_header_top_row,
.right_items,
.main_header_bottom_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left_nav_toggler {
    cursor: pointer;
}

.left_nav_toggler>.bar {
    display: block;
    width: 1.5rem;
    border-top: 2px solid var(--header-font-color);
    border-radius: 22px;
    position: relative;
    transition: all 350ms ease-in-out;
}

.left_nav_toggler>.bar:not(:last-child) {
    margin-bottom: 0.3rem;
}

.left_nav_toggler>.bar:nth-child(2) {
    left: 0.5rem;
    width: 1rem;
}

.left_nav_toggler>.bar:nth-child(3) {
    left: 0.5rem;
    width: 1rem;
}

.left_nav_toggler:hover .bar:nth-child(2),
.left_nav_toggler:hover .bar:nth-child(3) {
    left: 0;
    width: 1.5rem;
}

.site_logo {
    letter-spacing: 0.2rem;
    white-space: nowrap;
}

.right_items {
    width: 60px;
}

.right_items>a,
.site_logo>a {
    text-decoration: none;
    color: var(--header-font-color);
    transition: all 350ms ease-in-out;
}

.right_items>a:hover {
    color: var(--header-hover-font-color);
}

.right_items>svg {
    cursor: pointer;
}

.main_header_bottom_row {
    justify-content: flex-start;
    margin-top: 1rem;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.main_header_bottom_row::-webkit-scrollbar {
    display: none;
}

.main_header_bottom_row>.item {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--header-font-color);
    transition: all 350ms ease-in-out;
}

.main_header_bottom_row>.item:hover,
.main_header_bottom_row>.item.active {
    color: var(--header-hover-font-color);
}

.main_header_bottom_row>.item:first-child {
    margin-left: auto;
}

.main_header_bottom_row>.item:last-child {
    margin-right: auto;
}

.main_header_bottom_row>.item:not(:last-child) {
    margin-right: 1.5rem;
}

.main_header_bottom_row>.item>label {
    font-size: 0.6rem;
    margin-top: 0.5rem;
    white-space: nowrap;
    cursor: pointer;
}

.side_navigation {
    background-color: var(--header-bg-color);
    color: var(--header-font-color);
    position: fixed;
    z-index: 2;
    top: 0;
    height: 100%;
    left: 0;
    min-width: 150px;
    max-width: 250px;
    width: 70%;
    transition: all 350ms ease-in-out;
}

.side_navigation>.sidenav_site_logo {
    padding: 1rem 1rem 0 1rem;
    white-space: nowrap;
    letter-spacing: 0.2rem;
}

.side_navigation>.sidenav_contents {
    list-style: none;
    margin-top: 1rem;
}

.side_navigation>.sidenav_contents>li {
    width: 90%;
    margin: 0 auto;
    transition: all 350ms ease-in-out;
}

.side_navigation>.sidenav_contents>li>a {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: var(--header-font-color);
    display: flex;
    align-items: center;
    transition: all 350ms ease-in-out;
}

.side_navigation>.sidenav_contents>li>a:hover {
    color: var(--header-hover-font-color);
}

.side_navigation>.sidenav_contents>li>a>svg {
    margin-right: 0.5rem;
}

.side_navigation>.sidenav_contents>li>a>label {
    cursor: pointer;
}

.hide_side_navigation {
    left: -300px;
}

.side_navigation_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--black);
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.8;
    z-index: 1;
    transition: all 350ms ease-in-out;
}

.theme_switcher {
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: 0;
    padding: 1rem 1rem 0 1rem;
}