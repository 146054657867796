/* latin-ext */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQspDre2.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQUpDg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQspDre2.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQUpDg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQspDre2.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQUpDg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQspDre2.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQUpDg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQspDre2.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./fonts/xMQbuFFYT72XzQUpDg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Colors */
:root {
    /* Colors */
    --white: #fff;
    --black: rgba(0, 0, 0, .9);
    --lightblack: #212121;
    --darkblack: #101010;
    --grey: #ccc;
    --lightgrey: #eee;
    --brown: brown;
    --aqua: aqua;
    --teal: #008080;
    --red: #b31b1b;
    --green: #355e3b;
    --lightgreen: #9acd32;
    --orange: orange;

    /* To support light theme */
    --primary-color: var(--teal);
    --secondary-color: #212121;
    --primary-hover-color: var(--brown);

    --bg-color: var(--white);
    --font-color: var(--black);

    --header-bg-color: var(--white);
    --header-font-color: var(--black);
    --header-hover-font-color: var(--red);
    --header-hover-bg-color: var(--teal);

    --footer-bg-color: var(--white);
    --footer-font-color: var(--black);
    --footer-hover-font-color: var(--red);
    --header-hover-bg-color: var(--teal);
    --input-hover-color: var(--teal);

    --separator-color: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, #000000 50%, rgba(0, 0, 0, 0.00) 100%);

}

[data-theme="dark"] {
    /* To support dark theme */
    --primary-color: var(--teal);
    --secondary-color: var(--lightblack);
    --primary-hover-color: var(--brown);

    --bg-color: var(--lightblack);
    --font-color: var(--white);

    --header-bg-color: var(--lightblack);
    --header-font-color: var(--white);
    --header-hover-font-color: var(--lightgreen);
    --header-hover-bg-color: var(--teal);

    --footer-bg-color: var(--lightblack);
    --footer-font-color: var(--white);
    --footer-hover-font-color: var(--lightgreen);
    --footer-hover-bg-color: var(--teal);
    --input-hover-color: var(--lightgreen);

    --separator-color: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #ffffff 50%, rgba(255, 255, 255, 0.00) 100%);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Sora', sans-serif;
    font-size: 0.9rem;
    background-color: var(--bg-color);
    color: var(--font-color);
}

input,
button,
select,
textarea {
    font-family: inherit;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.separator {
    display: block;
    height: 1px;
    background-image: var(--separator-color);
    opacity: .1;
    width: 100%;
    margin-top: 1rem;
}

.text-center {
    text-align: center;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
    color: var(--footer-hover-font-color);
}

a:hover,
a:active {
    color: var(--footer-hover-font-color);
}

.DraftEditor-root {
    min-height: 100px;
    height: 250px !important;
}

.rdw-editor-toolbar,
.rdw-editor-toolbar a {
    color: var(--black) !important;
}